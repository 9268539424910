import { graphql, Link as GatsbyLink, StaticQuery } from 'gatsby';
import React from 'react';
import { Box, Flex, Image } from 'rebass';
import Container from './Container';

const Header = () => (
  <Box as="header" bg="blue" color="yellow">
    <Flex
      as={Container}
      flexDirection={['column', 'row']}
      justifyContent="center"
      alignItems="center"
      my={2}
    >
      <h1>Lukas Panczyk</h1>
    </Flex>
  </Box>
);

export default Header;
