import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Box, Image, Link, Text } from 'rebass';
import Container from './Container';

const Footer = () => (
  <Box as="footer" bg="blue" color="white">
    <Text as={Container} textAlign="center" pt={1} my={3}></Text>
  </Box>
);

export default Footer;
